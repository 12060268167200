<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="api"/>
        <v-row class="mt-3">
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <v-toolbar color="secondary" class="white--text mb-4">
                        <v-toolbar-title>
                            Upload comment
                        </v-toolbar-title>
                    </v-toolbar>
                    <div class="my-5">
                        <v-file-input
                        :rules="rules"
                        accept="xlsx"
                        @change="handleFile"
                        color="primary"
                        v-model="files"
                        outlined
                        prepend-icon=""
                        label="Please choose the files"
                        counter
                        :show-size="1000">
                            <template v-slot:selection="{index,text}">
                                <v-chip
                                v-if="index<2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small>
                                    {{ text }}
                                </v-chip>
                                <span
                                v-else-if="index==2" class="text-overline grey--text text--darken-3">
                                    +{{ files.length-2 }} Files(s)
                                </span>
                            </template>
                        </v-file-input>
    
                    </div>
                    <div>
                        <AError :api="this.api"/>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row
            v-if="this.comments!=null">
            <v-col>
                <v-data-table
                    :items="comments"
                    :headers="dataHeader"
                    :loading="isLoadingExtractData"
                    dense 
                    :search="search"
                    outlined>   
                    <template
                        v-slot:item.comment="{item}">
                        <div
                            v-if="item.comment!=null">
                            <!-- <span>
                                Comment by : {{ item.comment.comment.a }}
                            </span>
                            <span>
                                Comment : {{ item.comment.comment.t }}
                            </span> -->
                            {{ item.comment }}
                        </div>
                    </template>
                    <template
                        v-slot:item.company="{item}">
                        <div
                            v-if="item.company!=null">
                            <span>
                                {{ item.company }}
                            </span>
                        </div>
                    </template>
                    <template
                        v-slot:item.index="{item}">
                        {{ comments.indexOf(item)+1 }}
                    </template>
                    <template
                        v-slot:top>
                        <v-toolbar
                            flat 
                            class="text-h4 pt-4"
                            height="auto">
                            <v-row
                                no-gutters>
                                <v-col
                                    class="mt-3 mx-3">
                                    <v-row
                                        class="mb-3">
                                        <v-toolbar-title
                                            class="font-weight-bold text-h4 my-3">
                                            list of extracted comments
                                        </v-toolbar-title>
                                    </v-row>
                                    <v-row>
                                        <v-text-field
                                            v-model="search"
                                            dense
                                            outlined 
                                            label="Search">
                                        </v-text-field>
                                        <v-spacer>
                                        </v-spacer>
                                        <v-btn
                                            @click="transferData()"
                                            color="primary">
                                            Transfer
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-toolbar>

                    </template>

                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import AError from '../../components/common/AError.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
import XLSX from 'xlsx'
export default {
    components:{
    AConfirmation,
    AError,
    ASuccessFour
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        isHoverColor:'grey',
        search:"",
        isPending:false,
        rules:[
            value=>value[0].size < 10000000 || 'File size should be less than 10 MB!',
            value=>value.length<=1 || 'Total files that can be uploaded should be less than 3 files',
        ],
        files:[],
        remark:null,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:null,
        },
        companyId:null,
        comments:[],
        isLoadingExtractData:false,
        dataHeader:[
            {
                text:'#',
                value:'index'
            },
            {
                text:'Ref cell',
                value:'cellRef'
            },
            {
                text:'Cell',
                value:'row.v'
            },
            {
                text:'Company',
                value:'company'
            },
            {
                text:'Type',
                value:'type'
            },
            {
                text:'Written by',
                value:'writtenBy'
            },
            {
                text:'Comment',
                value:'comment'
            },
          
        ]
    }),
    created() {
        this.api.callbackReset = () =>
        {
            this.api.isLoading = true;
            this.api.isError = false;
            this.api.isSuccesful = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isError = true;
            this.api.isLoading = false;
            this.api.isSuccesful = false;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class=='uploadExcelIndustry'){
                this.api.success = "Succesfully upload the file";
                this.api.isSuccesful = true;
            }
            
            this.api.isError = false;
            this.api.isLoading = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        dropHandler(ev){
            ev.preventDefault();

            if(ev.dataTransfer.items){
                let allowedFilesFormat = ["image/png","image/jpeg","application/pdf"];

                [...ev.dataTransfer.items].forEach((item) => {
                    // if(item.kind === "file"){
                    // }
                    const file = item.getAsFile();
                    // let type = file.type;
                    if(!allowedFilesFormat.includes(file.type)){
                        this.api.isError = true;
                        this.api.error = "Cannot upload"+ file.name+" , invalid format";
                        return 1;
                    }
                    this.files.push(file);
                });
            }
            this.isHoverColor="grey"
        },
        dragOverHandler(){
            this.isHoverColor = "blue";
        },
        onDragLeave(){
            this.isHoverColor="grey";
        },
        fetch(){
            this.companyId = this.$router.history.current.params.id;

        },
        redirectBack(){
            this.$router.go(-1);
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let createAttachmentApi;
            createAttachmentApi = this.create();
            this.$api.fetch(createAttachmentApi);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
            let tempApi = this.$_.clone(this.api);
            tempApi.methods = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/developer/excel/industry/'+this.$store.getters.getUserId;
            let formData = new FormData();
            if(this.files!=null){
                formData.append('attachment',this.files);
            }
            tempApi.params = formData;
            return tempApi;
        },
        handleFile() {

            if(this.files) {
                this.isLoadingExtractData = true;
                const reader = new FileReader;
                reader.onload = (e)=> {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data,{type:'array'});
                
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];

                    this.comments = this.extractComment(sheet);
                    this.isLoadingExtractData = false;

                };
                reader.readAsArrayBuffer(this.files);
            }
        },
        extractComment(sheet) {
            const comments = [];
            Object.keys(sheet).forEach((cellRef)=>{
                const cell = sheet[cellRef];

                if(typeof(cell.c) != 'undefined') {
                    // BOC : row will get the number position of the row
                    let row = parseInt(cellRef.replace(/[^0-9]/g,''),10);
                    // EOC
                    // BOC : Col will get the char of the column
                    let col = cellRef.replace(/\d/g, '')
                    let company = sheet["A"+row].v;
                    let type = sheet[col+"1"].v;
                    let visionKey = company.toUpperCase();
                    visionKey = visionKey.replace(/[^a-zA-Z0-9]/g,'');
                    
                    cell.c.forEach(data=>{
                        let username = data.a.toUpperCase();
                        username = username.replace(/\s/g, '');
                        const comment = {
                            cellRef,
                            company:company,
                            row:cell,
                            type:type,
                            comment:data.t,
                            writtenBy:data.a,
                            username:username,
                            visionKey:visionKey,
                        };  

                        comments.push(comment);
                    });

                    // const comment = {
                    //         cellRef,
                    //         company:company,
                    //         row:cell,
                    //         type:type,
                    //         comment:cell.c,
                    //         visionKey:visionKey,
                    //     };  

                    //     comments.push(comment);
                }
            });
           
            return comments;
        },
        transferData() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/comment";
            let comments = this.$_.clone(this.comments);
            let tempComment = []
            let formData = new FormData;
            tempApi.params = tempComment;
            comments.forEach(data=>{
                
                let tempData = {
                    'company':data.company,
                    'comment': data.comment,
                    'username':data.a,
                    'column':data.type,
                    'cellref':data.cellRef,
                };
                formData.append('data[]',tempData);

            }); 
            tempApi.headers={
                'Content-Type':'application/json'
            };
            tempApi.params ={
                'data':this.comments,
            
            }
           
            this.$api.fetch(tempApi);

        }
    }
}
</script>
<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>