var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('AConfirmation',{attrs:{"isShow":_vm.isPending},on:{"cancel":_vm.cancelSubmit,"confirm":_vm.submit}}),_c('ASuccessFour',{attrs:{"api":_vm.api}}),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"mx-auto",attrs:{"align":"center","justify":"center","cols":"12","sm":"8"}},[_c('v-col',{attrs:{"align":"start","justify":"start"}},[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary"},on:{"click":_vm.redirectBack}},[_c('v-icon',[_vm._v(" mdi-arrow-left-bold ")])],1)],1),_c('v-card',{staticClass:"pa-3",attrs:{"elevation-1":""}},[_c('v-toolbar',{staticClass:"white--text mb-4",attrs:{"color":"secondary"}},[_c('v-toolbar-title',[_vm._v(" Upload comment ")])],1),_c('div',{staticClass:"my-5"},[_c('v-file-input',{attrs:{"rules":_vm.rules,"accept":"xlsx","color":"primary","outlined":"","prepend-icon":"","label":"Please choose the files","counter":"","show-size":1000},on:{"change":_vm.handleFile},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index<2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index==2)?_c('span',{staticClass:"text-overline grey--text text--darken-3"},[_vm._v(" +"+_vm._s(_vm.files.length-2)+" Files(s) ")]):_vm._e()]}}]),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1),_c('div',[_c('AError',{attrs:{"api":this.api}})],1)],1)],1)],1),(this.comments!=null)?_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"items":_vm.comments,"headers":_vm.dataHeader,"loading":_vm.isLoadingExtractData,"dense":"","search":_vm.search,"outlined":""},scopedSlots:_vm._u([{key:"item.comment",fn:function(ref){
var item = ref.item;
return [(item.comment!=null)?_c('div',[_vm._v(" "+_vm._s(item.comment)+" ")]):_vm._e()]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [(item.company!=null)?_c('div',[_c('span',[_vm._v(" "+_vm._s(item.company)+" ")])]):_vm._e()]}},{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.comments.indexOf(item)+1)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"text-h4 pt-4",attrs:{"flat":"","height":"auto"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-3 mx-3"},[_c('v-row',{staticClass:"mb-3"},[_c('v-toolbar-title',{staticClass:"font-weight-bold text-h4 my-3"},[_vm._v(" list of extracted comments ")])],1),_c('v-row',[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.transferData()}}},[_vm._v(" Transfer ")])],1)],1)],1)],1)]},proxy:true}],null,false,2630695432)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }